angular.module('app')
    .factory('accountService', ["$log", "$http", function($log, $http) {

        var url = {
            getTransactions: function (organisation) {
                return sprintf('/account/%s/transactions', organisation);
            },
            getTransaction: function (transactionReference) {
                return sprintf('/account/transaction/%s', transactionReference);
            },
            getStaffTransactions: function (organisation, staffReference) {
                return sprintf('/account/organisations/%s/staff/%s/transaction', organisation, staffReference);
            }
        };

        var api = {
            getTransactions: function (organisation, params) {
                return $http.get(url.getTransactions(organisation), { params: params})
                    .then(function (result) {
                        return result.data;
                    });
            },
            getTransaction: function (transactionReference) {
                return $http.get(url.getTransaction(transactionReference))
                    .then(function (response) {
                        return response.data;
                    });
            },
            getStaffTransactions: function( organisation, staffReference, params) {
                return $http.get(url.getStaffTransactions(organisation, staffReference), { params: params})
                    .then(function (result) {
                        return result.data;
                    });
            }
        };

        return api;

    }]);